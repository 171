{
  "locale": "pl",
  "languages": {
    "aa": "afar",
    "ab": "abchaski",
    "ae": "awestyjski",
    "af": "africaans",
    "ak": "akan",
    "am": "amharski",
    "an": "aragoński",
    "ar": "arabski",
    "as": "asamski",
    "av": "awarski",
    "ay": "ajmara",
    "az": "azerski",
    "ba": "baszkirski",
    "be": "białoruski",
    "bg": "bułgarski",
    "bh": "języki bihari",
    "bi": "bislama",
    "bm": "bambara",
    "bn": "bengalski",
    "bo": "tybetański",
    "br": "bretoński",
    "bs": "bośniacki",
    "ca": "kataloński",
    "ce": "czeczeński",
    "ch": "czamorro",
    "co": "korsykański",
    "cr": "kri",
    "cs": "czeski",
    "cu": "staro-cerkiewno-słowiański",
    "cv": "czuwaski",
    "cy": "walijski",
    "da": "duński",
    "de": "niemiecki",
    "dv": "malediwski",
    "dz": "dzongkha",
    "ee": "ewe",
    "el": "grecki",
    "en": "angielski",
    "eo": "esperanto",
    "es": "hiszpański",
    "et": "estoński",
    "eu": "baskijski",
    "fa": "perski",
    "ff": "fulani",
    "fi": "fiński",
    "fj": "fidżyjski",
    "fo": "farerski",
    "fr": "francuski",
    "fy": "zachodniofryzyjski",
    "ga": "irlandzki",
    "gd": "szkocki gaelicki",
    "gl": "galicyjski",
    "gn": "guarani",
    "gu": "gudźaracki",
    "gv": "manx",
    "ha": "hausa",
    "he": "hebrajski",
    "hi": "hindi",
    "ho": "hiri motu",
    "hr": "chorwacki",
    "ht": "haitański",
    "hu": "węgierski",
    "hy": "ormiański",
    "hz": "herero",
    "ia": "interlingua",
    "id": "indonezyjski",
    "ie": "interlingue",
    "ig": "igbo",
    "ii": "syczuański",
    "ik": "inupiaq",
    "io": "ido",
    "is": "islandzki",
    "it": "włoski",
    "iu": "inuktitut",
    "ja": "japoński",
    "jv": "jawajski",
    "ka": "gruziński",
    "kg": "kongo",
    "ki": "kikuju",
    "kj": "kwanyama",
    "kk": "kazachski",
    "kl": "grenlandzki",
    "km": "khmerski",
    "kn": "kannada",
    "ko": "koreański",
    "kr": "kanuri",
    "ks": "kaszmirski",
    "ku": "Kurdyjski",
    "kv": "komi",
    "kw": "kornijski",
    "ky": "kirgiski",
    "la": "Łacina",
    "lb": "luksemburski",
    "lg": "ganda",
    "li": "limburski",
    "ln": "lingala",
    "lo": "laotański",
    "lt": "litewski",
    "lu": "luba-katanga",
    "lv": "łotewski",
    "mg": "malgaski",
    "mh": "marszalski",
    "mi": "maori",
    "mk": "macedoński",
    "ml": "malajalam",
    "mn": "mongolski",
    "mr": "marathi",
    "ms": "malajski",
    "mt": "maltański",
    "my": "birmański",
    "na": "nauruański",
    "nb": "norweski (bokmål)",
    "nd": "ndebele północny",
    "ne": "nepalski",
    "ng": "ndonga",
    "nl": "niderlandzki",
    "nn": "norweski (nynorsk)",
    "no": "norweski",
    "nr": "ndebele południowy",
    "nv": "nawaho",
    "ny": "czewa",
    "oc": "oksytański",
    "oj": "odżibwe",
    "om": "oromski",
    "or": "orija",
    "os": "osetyjski",
    "pa": "pendżabski",
    "pi": "pali",
    "pl": "polski",
    "ps": "paszto",
    "pt": "portugalski",
    "qu": "keczua",
    "rm": "retoromański",
    "rn": "rundi",
    "ro": "rumuński",
    "ru": "rosyjski",
    "rw": "kinya-ruanda",
    "sa": "sanskryt",
    "sc": "sardyński",
    "sd": "sindhi",
    "se": "lapoński północny",
    "sg": "sango",
    "si": "syngaleski",
    "sk": "słowacki",
    "sl": "słoweński",
    "sm": "samoański",
    "sn": "szona",
    "so": "somalijski",
    "sq": "albański",
    "sr": "serbski",
    "st": "sotho",
    "ss": "suazi",
    "su": "sundajski",
    "sv": "szwedzki",
    "sw": "suahili",
    "ta": "tamilski",
    "te": "telugu",
    "tg": "tadżycki",
    "th": "tajski",
    "ti": "tigrinia",
    "tk": "turkmeński",
    "tl": "tagalski",
    "tn": "tswana",
    "to": "tonga",
    "tr": "turecki",
    "ts": "tsonga",
    "tt": "tatarski",
    "tw": "twi",
    "ty": "tahitański",
    "ug": "ujgurski",
    "uk": "ukraiński",
    "ur": "urdu",
    "uz": "uzbecki",
    "ve": "venda",
    "vi": "wietnamski",
    "vo": "wolapik",
    "wa": "waloński",
    "wo": "wolof",
    "xh": "xhosa",
    "yi": "jidysz",
    "yo": "joruba",
    "za": "zhuang",
    "zh": "chiński",
    "zu": "zulu"
  }
}
