@for (navigationUnit of navigationUnits$ | async; track $index) {
    <div class="relative flex h-full">
        <button
            matRipple
            class="shrl-text-h5-medium my-auto mr-3 flex text-nowrap rounded-md px-4 py-3 text-shrl-black hover:bg-slate-100"
            (click)="navigate(navigationUnit)"
            aria-label="Navigation">
            @if (navigationUnit.iconName) {
                <shrl-icon
                    class="mr-1.5"
                    [iconName]="navigationUnit.iconName"></shrl-icon>
            }
            @if (navigationUnit.label) {
                <span>
                    {{ navigationUnit.label | translate }}
                </span>
            }
        </button>
        <div
            [ngClass]="{
                'opacity-100': isActive(navigationUnit),
                'opacity-0': !isActive(navigationUnit),
                'h-full w-2': isHorizontal,
                'h-2 w-full': !isHorizontal
            }"
            class="absolute bottom-0 right-0 h-2 w-full bg-shrl-cyan-500"></div>
    </div>
}
