import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Input,
  OnInit,
  inject,
} from '@angular/core';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { SharelockCommonModule } from '../../../../../share/modules/sharelock-common.module';
import { MatButtonModule } from '@angular/material/button';
import { ENavigationRoute } from '../../../../model/navigation.model';
import { AccountDataService } from '../../../../services/auth/account-data.service';
import { SnackBarService } from '../../../../../share/services/utils/snack-bar.service';
import { TranslateService } from '@ngx-translate/core';
import { RefreshTokenService } from '../../../../services/auth/refresh-token/refresh-token.service';
import { AccessTokenService } from '../../../../services/auth/access-token/access-token.service';
import { IAccountDetailsDTO } from '../../../../../features/account/model/account.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'shrl-account-buttons',
  standalone: true,
  imports: [SharelockCommonModule, MatButtonModule, RouterOutlet, RouterLink],
  templateUrl: './account-buttons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountButtonsComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  ENavigationRoute = ENavigationRoute;
  @Input() isHorizontal = false;

  accountData: IAccountDetailsDTO | null = null;

  constructor(
    private router: Router,
    private snackBar: SnackBarService,
    private translate: TranslateService,
    private refreshTokenService: RefreshTokenService,
    private accessTokenService: AccessTokenService,
    private accountDataService: AccountDataService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.setAccountDataSubscriber();
  }

  private setAccountDataSubscriber() {
    this.accountDataService.accountData$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data) => {
        this.accountData = data;
        this.cdr.detectChanges();
      });
  }

  logOut() {
    this.refreshTokenService.removeToken();
    this.accessTokenService.removeToken();
    this.accountDataService.removeData();
    this.router.navigate([ENavigationRoute.LOGIN]);
    this.snackBar.openSnackbarSuccess({
      label: this.translate.instant('SHARED.GENERAL.LOGGED_OUT'),
    });
  }
}
