import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FrameComponent } from './core/components/frame/frame.component';
import { SharelockCommonModule } from './share/modules/sharelock-common.module';

@Component({
  selector: 'shrl-root',
  standalone: true,
  imports: [SharelockCommonModule, RouterOutlet, FrameComponent],
  templateUrl: './shrl.component.html',
  styleUrl: './shrl.component.scss',
})
export class ShrlComponent {}
