<div
    class="flex w-full flex-col"
    [@appear]="{
        value: animationState$ | async,
        params: { opacityMaxValue: 1, duration: '0.2' }
    }"
    (@appear.done)="setDisplayTree()">
    @if (parent) {
        <button
            matRipple
            class="shrl-text-h5-medium my-auto mr-3 flex rounded-md px-4 py-3 text-shrl-black hover:bg-slate-100"
            (click)="selectParents()">
            <mat-icon class="my-auto mr-3 !h-8 !w-8 !text-3xl">
                keyboard_arrow_left
            </mat-icon>
            @if (parent.iconName) {
                <shrl-icon
                    class="my-auto mr-2 h-6 w-6"
                    [iconName]="parent.iconName"></shrl-icon>
            }
            @if (parent.label) {
                <span class="my-auto">
                    {{ parent.label | translate }}
                </span>
            }
        </button>
    }

    @for (navigationUnit of displayedUnits; track $index) {
        <div class="relative flex justify-center p-1">
            @if (navigationUnit?.matchFullPath) {
                <div
                    class="absolute left-0 top-0 my-px h-full w-1.5 bg-shrl-cyan-500"></div>
            }
            <div class="mr-auto flex">
                <button
                    matRipple
                    class="shrl-text-h5-medium my-auto ml-2 flex rounded-md px-4 py-3 text-shrl-black hover:bg-slate-100"
                    (click)="onNavigationLabelClick(navigationUnit)"
                    aria-label="Navigation">
                    @if (navigationUnit.iconName) {
                        <shrl-icon
                            class="my-auto mr-2 h-6 w-6"
                            [iconName]="navigationUnit.iconName"></shrl-icon>
                    }
                    @if (navigationUnit.label) {
                        <span class="my-auto">
                            {{ navigationUnit.label | translate }}
                        </span>
                    }
                </button>
                @if (navigationUnit.navigationChildren?.length) {
                    <button
                        matRipple
                        class="my-auto flex items-center rounded-full p-2"
                        (click)="onNavigationArrowClick(navigationUnit)">
                        <mat-icon class="!h-8 !w-8 !text-3xl">
                            keyboard_arrow_right
                        </mat-icon>
                    </button>
                }
            </div>
        </div>
    }
</div>
