import {
  animate,
  trigger,
  state,
  style,
  transition,
} from '@angular/animations';

export enum AnimationStateE {
  SHOW = 'show',
  HIDE = 'hide',
}

export const SharelockAnimations = {
  appear: trigger('appear', [
    state(
      AnimationStateE.SHOW,
      style({
        opacity: '{{opacityMaxValue}}',
      }),
      { params: { opacityMaxValue: '1' } }
    ),
    state(
      AnimationStateE.HIDE,
      style({
        opacity: '{{opacityMinValue}}',
      }),
      { params: { opacityMinValue: '0' } }
    ),
    transition('* => show', [animate('{{duration}}s {{delay}}s ease')], {
      params: { duration: '0.5', delay: '0' },
    }),
    transition('* => hide', [animate('0.2s 0s ease')]),
    transition('* => show', [animate('{{duration}}s {{delay}}s ease')], {
      params: { duration: '0.5', delay: '0' },
    }),
  ]),

  scale: trigger('scale', [
    state(
      AnimationStateE.SHOW,
      style({
        transform: 'scale({{scaleShow}})',
      }),
      { params: { scaleShow: '1' } }
    ),
    state(
      AnimationStateE.HIDE,
      style({
        transform: 'scale({{scaleHide}})',
      }),
      { params: { scaleHide: '0' } }
    ),
    transition('hide <=> show', [animate('{{duration}}s {{delay}}s ease')], {
      params: { duration: '0.5', delay: '0' },
    }),
  ]),

  transformY: trigger('transformY', [
    state(
      AnimationStateE.SHOW,
      style({
        transform: 'translateY({{translateShow}})',
      }),
      { params: { translateShow: '0' } }
    ),
    state(
      AnimationStateE.HIDE,
      style({
        transform: 'translateY({{translateHide}})',
      }),
      { params: { translateHide: '0' } }
    ),
    transition('hide <=> show', [animate('{{duration}}s {{delay}}s ease')], {
      params: { duration: '0.5', delay: '0' },
    }),
  ]),

  transformX: trigger('transformX', [
    state(
      AnimationStateE.SHOW,
      style({
        transform: 'translateX({{translateShow}})',
      }),
      { params: { translateShow: '0' } }
    ),
    state(
      AnimationStateE.HIDE,
      style({
        transform: 'translateX({{translateHide}})',
      }),
      { params: { translateHide: '0' } }
    ),
    transition('hide <=> show', [animate('{{duration}}s {{delay}}s ease')], {
      params: { duration: '0.5', delay: '0' },
    }),
  ]),

  backgroundColor: trigger('backgroundColor', [
    state(
      AnimationStateE.SHOW,
      style({
        backgroundColor: '{{showBackgroundValue}}',
      }),
      { params: { showBackgroundValue: 'rgb(255, 255, 255)' } }
    ),
    state(
      AnimationStateE.HIDE,
      style({
        backgroundColor: '{{hideBackgroundValue}}',
      }),
      { params: { hideBackgroundValue: 'rgb(0, 0, 0)' } }
    ),
    transition('hide <=> show', [animate('{{duration}}s {{delay}}s ease')], {
      params: { duration: '0.5', delay: '0' },
    }),
  ]),
};
