import {
  Component,
  ChangeDetectionStrategy,
  inject,
  DestroyRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import {
  BehaviorSubject,
  combineLatest,
  delay,
  filter,
  map,
  startWith,
} from 'rxjs';
import {
  SharelockAnimations,
  AnimationStateE,
} from '../../../../../share/utils/animations';
import { SharelockCommonModule } from '../../../../../share/modules/sharelock-common.module';
import {
  INavigationDataTreeUnit,
  ENavigationUnitComponentSelection,
} from '../../../../model/navigation.model';
import { NavigationService } from '../../../../services/navigation.service';
import { Router } from '@angular/router';
import { AccountDataService } from '../../../../services/auth/account-data.service';
import { IconComponent } from '../../../../../share/components/icon/icon.component';
import { MatRippleModule } from '@angular/material/core';
import { isNavUnitVisible } from '../../navigation.utils';

@Component({
  selector: 'shrl-sidenav-navigation-buttons',
  standalone: true,
  imports: [
    SharelockCommonModule,
    MatIconModule,
    IconComponent,
    MatRippleModule,
    MatIconModule,
  ],
  templateUrl: './sidenav-navigation-buttons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [SharelockAnimations.appear, SharelockAnimations.transformX],
})
export class SidenavNavigationButtonsComponent {
  private readonly destroyRef = inject(DestroyRef);
  AnimationStateE = AnimationStateE;

  private _navigationUnits?: INavigationDataTreeUnit[];
  private _selectedUnits?: INavigationDataTreeUnit[];

  displayedUnits?: INavigationDataTreeUnit[] = this._selectedUnits;
  parent?: INavigationDataTreeUnit;
  parents?: INavigationDataTreeUnit[];

  private _animationState$ = new BehaviorSubject<AnimationStateE>(
    AnimationStateE.SHOW
  );
  animationState$ = this._animationState$.asObservable();

  constructor(
    private navigationService: NavigationService,
    private _router: Router,
    private accountData: AccountDataService
  ) {
    this.setNavigationServiceSubscribers();
  }

  // ON CLICK ACTIONS
  onNavigationLabelClick(navigationUnit: INavigationDataTreeUnit) {
    if (navigationUnit.isRedirected) {
      this.onNavigationArrowClick(navigationUnit);
    } else if (navigationUnit?.fullPath) {
      this._router.navigateByUrl(navigationUnit.fullPath);
    }
  }

  onNavigationArrowClick(navigationUnit: INavigationDataTreeUnit) {
    if (navigationUnit?.navigationChildren?.length) {
      this._animationState$.next(AnimationStateE.HIDE);
      this._selectedUnits = navigationUnit.navigationChildren;
    } else {
      this.onNavigationLabelClick(navigationUnit);
    }
  }

  // SETTERS
  selectParents() {
    this._animationState$.next(AnimationStateE.HIDE);
    this._selectedUnits = this.parents;
  }

  setDisplayTree() {
    this.displayedUnits = this._selectedUnits;
    this.parent = this.getParent(
      this._navigationUnits,
      this.displayedUnits?.at(0)
    );
    this.parents = this.getLeafs(this.parent, this._navigationUnits);
    this._animationState$.next(AnimationStateE.SHOW);
  }

  private setNavigationUnits = (navigationUnits: INavigationDataTreeUnit[]) => {
    this._navigationUnits = navigationUnits;
    this._selectedUnits = this.getActiveNavigationUnits(this._navigationUnits);
    if (!this._selectedUnits?.length) {
      this._selectedUnits = this._navigationUnits;
    }
    this.setDisplayTree();
  };

  private resetNavigationUnits = () => {
    this.setNavigationUnits(this._navigationUnits ?? []);
  };

  private setNavigationServiceSubscribers() {
    combineLatest([
      this.navigationService.getFilterednavigationUnits$({
        componentSelection: ENavigationUnitComponentSelection.SIDENAV,
        hasLabel: true,
      }),
      this.accountData.userRoles$.pipe(startWith(undefined)),
    ])
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map(([navUnits, userRoles]) =>
          navUnits.filter((unit) => isNavUnitVisible(unit, userRoles))
        )
      )
      .subscribe(this.setNavigationUnits);

    this.navigationService.sidenavOpened$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((opened) => !opened),
        delay(500)
      )
      .subscribe(this.resetNavigationUnits);
  }

  // UTILS
  private getActiveNavigationUnits(
    units: INavigationDataTreeUnit[]
  ): INavigationDataTreeUnit[] {
    const isActiveInCurrentLevel = units.some((unit) => unit.matchFullPath);

    if (isActiveInCurrentLevel) {
      return units;
    }

    let activeUnits: INavigationDataTreeUnit[] = [];
    units.forEach((unit) => {
      if (unit.navigationChildren && unit.navigationChildren.length > 0) {
        const childActiveUnits = this.getActiveNavigationUnits(
          unit.navigationChildren
        );
        activeUnits = activeUnits.concat(childActiveUnits);
      }
    });

    return activeUnits;
  }

  private getLeafs(
    leaf?: INavigationDataTreeUnit,
    units?: INavigationDataTreeUnit[]
  ): INavigationDataTreeUnit[] | undefined {
    if (!units || !leaf) {
      return undefined;
    }

    if (units.some((unit) => unit === leaf)) {
      return units;
    }

    let foundLeafs: INavigationDataTreeUnit[] = [];
    units.forEach((unit) => {
      const leafs = this.getLeafs(leaf, unit?.navigationChildren);
      if (leafs) {
        foundLeafs = foundLeafs.concat(leafs);
      }
    });
    return foundLeafs.length > 0 ? foundLeafs : undefined;
  }

  private getParent(
    roots?: INavigationDataTreeUnit[],
    child?: INavigationDataTreeUnit
  ): INavigationDataTreeUnit | undefined {
    if (!roots || roots.length === 0 || !child) {
      return undefined;
    }

    for (const root of roots) {
      if (root.navigationChildren && root.navigationChildren.includes(child)) {
        return root;
      }
      const parent = this.getParent(root.navigationChildren || [], child);
      if (parent) {
        return parent;
      }
    }
    return undefined;
  }
}
