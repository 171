import {
  HttpInterceptorFn,
  HttpRequest,
  HttpHandlerFn,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

export const apiInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  // Check if the request URL corresponds to loading SVG icons
  // If it's an SVG icon request, don't modify the request and pass it through
  if (isSvgIconRequest(req.url)) {
    return next(req);
  }

  const modifiedRequest = req.clone({
    url: `${environment.apiUrl}/api${req.url}`,
  });

  return next(modifiedRequest);
};

function isSvgIconRequest(url: string): boolean {
  return url.includes('assets/icons/');
}
