import { SALES_CONTACT_INFO } from '../../../share/utils/constans/contact-info';
import { NFooterData } from './footer.model';

export const FOOTER_CONTACT_DATA: NFooterData.IContactData[] = [
  {
    heading: 'SHARED.GENERAL.ADDRESS',
    data: 'SHARED.CONTACT_INFO.FULL_ADDRESS',
  },
  {
    heading: 'SHARED.GENERAL.TELEPHONE',
    data: SALES_CONTACT_INFO.PHONE_NUMBER,
  },
  {
    heading: 'SHARED.GENERAL.EMAIL',
    data: SALES_CONTACT_INFO.EMAIL,
  },
];
