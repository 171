import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Input,
  inject,
} from '@angular/core';
import { SharelockCommonModule } from '../../../../../share/modules/sharelock-common.module';
import { TranslateService } from '@ngx-translate/core';
import { ELanguageCode } from '../../../../../share/utils/enums/country.enum';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LanguageUnit } from '../../../../model/language.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'shrl-language-button',
  standalone: true,
  imports: [SharelockCommonModule],
  templateUrl: './language-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageButtonComponent {
  private readonly destroyRef = inject(DestroyRef);

  @Input({ required: true }) isHorizontal = false;

  readonly languageUnits$: Observable<LanguageUnit[]>;

  constructor(private translate: TranslateService) {
    this.languageUnits$ = this.getLanguageUnits$();
  }

  changeLang(code: ELanguageCode) {
    this.translate.setDefaultLang(code);
  }

  private getLanguageUnits$ = (): Observable<LanguageUnit[]> =>
    this.translate.onDefaultLangChange.pipe(
      takeUntilDestroyed(this.destroyRef),
      map((langChange) => langChange.lang),
      startWith(this.translate.defaultLang),
      map((lang) => this.mapLangs(lang as ELanguageCode))
    );

  private mapLangs = (selectedLang: ELanguageCode): LanguageUnit[] =>
    this.translate
      .getLangs()
      .map((lang) =>
        this.createLanguageUnit(lang as ELanguageCode, lang === selectedLang)
      );

  private createLanguageUnit(
    code: ELanguageCode,
    isSelected?: boolean
  ): LanguageUnit {
    return {
      label: code.toUpperCase(),
      code,
      isSelected,
    };
  }
}
