import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SharelockCommonModule } from '../../../../../share/modules/sharelock-common.module';
import {
  ENavigationUnitComponentSelection,
  INavigationDataTreeUnit,
} from '../../../../model/navigation.model';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';
import { SharelockAnimations } from '../../../../../share/utils/animations';
import { Router } from '@angular/router';
import { Observable, combineLatest, map, startWith } from 'rxjs';
import { NavigationService } from '../../../../services/navigation.service';
import { AccountDataService } from '../../../../services/auth/account-data.service';

import { IconComponent } from '../../../../../share/components/icon/icon.component';
import { isNavUnitVisible } from '../../navigation.utils';

@Component({
  selector: 'shrl-top-bar-navigation-buttons',
  standalone: true,
  imports: [
    SharelockCommonModule,
    MatRippleModule,
    MatIconModule,
    IconComponent,
  ],
  templateUrl: './top-bar-navigation-buttons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [SharelockAnimations.appear],
})
export class TopBarNavigationButtonsComponent {
  @Input() isHorizontal = false;

  navigationUnits$?: Observable<INavigationDataTreeUnit[]>;

  constructor(
    public navigationService: NavigationService,
    private _router: Router,
    private accountData: AccountDataService
  ) {
    this.setNavigationUnitsObservable();
  }

  isActive = (navItem: INavigationDataTreeUnit) =>
    // filter out initial routing
    navItem.fullPath === '/' ? navItem.matchFullPath : navItem.matchPartialPath;

  navigate(navigationUnit: INavigationDataTreeUnit) {
    this._router.navigateByUrl(navigationUnit?.fullPath);
  }

  private setNavigationUnitsObservable() {
    this.navigationUnits$ = combineLatest([
      this.navigationService.getFilterednavigationUnits$({
        componentSelection: ENavigationUnitComponentSelection.TOPBAR,
      }),
      this.accountData.userRoles$.pipe(startWith(undefined)),
    ]).pipe(
      map(([navUnits, userRoles]) =>
        navUnits.filter((unit) => isNavUnitVisible(unit, userRoles))
      )
    );
  }
}
