<div class="flex h-full flex-col justify-between">
    <shrl-sidenav-navigation-buttons> </shrl-sidenav-navigation-buttons>
    <div>
        <div class="ml-7 mt-10">
            <shrl-language-button [isHorizontal]="false"></shrl-language-button>
        </div>
        <div class="mx-5 mt-5">
            <shrl-account-buttons [isHorizontal]="false">
            </shrl-account-buttons>
        </div>
        <div class="shrl-text-body-1 mb-8 ml-5 mr-3 mt-5">
            <span>{{ 'SHARED.GENERAL.CONTACT_AT' | translate }} <br /></span>
            <span class="flex">
                <mat-icon class="mr-2">support_agent</mat-icon
                >{{ SUPPORT_CONTACT_INFO.PHONE_NUMBER }}
                <br />
            </span>
            <span class="flex">
                <mat-icon class="mr-2">mail_outline</mat-icon>
                {{ SUPPORT_CONTACT_INFO.EMAIL }}
                <br />
            </span>
        </div>
    </div>
</div>
