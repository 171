import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, repeat, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AccessTokenService } from '../services/auth/access-token/access-token.service';
import { RefreshTokenService } from '../services/auth/refresh-token/refresh-token.service';
import { AccountDataService } from '../services/auth/account-data.service';
import { SnackBarService } from '../../share/services/utils/snack-bar.service';
import {
  IHttpErrorResponse,
  IHttpErrorResponseDetails,
} from '../model/api/http-response.model';

@Injectable({
  providedIn: null,
})
export class AuthInterceptor implements HttpInterceptor {
  private _refreshingTokenSemaphore = false;

  constructor(
    private accessTokenService: AccessTokenService,
    private refreshTokenService: RefreshTokenService,
    private AccountDataService: AccountDataService,
    private snackBarService: SnackBarService
  ) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // if no auth required
    if (this.isRequest_Not_RequiredAuth(req)) {
      return next.handle(req.clone()).pipe(
        catchError((error: IHttpErrorResponse) => {
          this.snackBarService.openSnackbarError({
            label: `Error: ${error.error.title}`,
          }); // translation TODO - waiting for backend
          return throwError(() => error);
        })
      );
    }

    // auth required
    return next.handle(this.addAuthorizationHeader(req)).pipe(
      catchError((error: IHttpErrorResponseDetails) => {
        // 401 means there is problem with authorization
        if (error.status === 401) {
          // get refresh token
          const refreshToken = this.refreshTokenService.retrieveToken();
          if (refreshToken) {
            // delay if refreshToken is updating...
            if (this._refreshingTokenSemaphore) {
              return next.handle(req).pipe(repeat({ delay: 2000 }));
            }
            // refresh accessToken from refreshToken
            return this.handleUnauthorizedError(req, next, refreshToken);
          } else {
            // there isn't refresh token, so remove accessToken
            if (this.accessTokenService.hasToken) {
              this.clearAuthData();
            }
          }
        }
        return throwError(() => error);
      })
    );
  }

  private addAuthorizationHeader(
    req: HttpRequest<unknown>
  ): HttpRequest<unknown> {
    const accessToken = this.accessTokenService.retrieveToken();
    if (accessToken != null) {
      return req.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    }
    return req;
  }

  private handleUnauthorizedError(
    req: HttpRequest<unknown>,
    next: HttpHandler,
    refreshToken: string
  ): Observable<HttpEvent<unknown>> {
    this._refreshingTokenSemaphore = true;
    return this.accessTokenService.refresh(refreshToken).pipe(
      catchError((error: IHttpErrorResponse) => {
        if (error.status === 401) {
          this.clearAuthData();
        }
        console.error('Error refreshing access token:', error.error.title);
        this._refreshingTokenSemaphore = false;
        return throwError(() => error);
      }),
      switchMap((response) => {
        this._refreshingTokenSemaphore = false;
        if (response) {
          this.accessTokenService.storeToken(response.id_token);
          this.refreshTokenService.storeToken(response.refresh_token);
          return next.handle(this.addAuthorizationHeader(req));
        }
        return next.handle(req);
      })
    );
  }

  private isRequest_Not_RequiredAuth(req: HttpRequest<unknown>) {
    return (
      req.url.includes('assets/icons/') ||
      req.headers.get('No-Auth') === 'True' ||
      req.url.includes('/refresh-token')
    );
  }

  private clearAuthData() {
    this.refreshTokenService.removeToken();
    this.accessTokenService.removeToken();
    this.AccountDataService.removeData();
  }
}
