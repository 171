import {
  Component,
  ChangeDetectionStrategy,
  inject,
  DestroyRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { Observable } from 'rxjs';
import { COPYRIGHT } from '../../../share/utils/constans/copyright';
import { SharelockCommonModule } from '../../../share/modules/sharelock-common.module';
import {
  INavigationDataTreeUnit,
  ENavigationUnitComponentSelection,
} from '../../model/navigation.model';
import { NavigationService } from '../../services/navigation.service';
import { FOOTER_CONTACT_DATA } from './footer.data';

@Component({
  selector: 'shrl-footer',
  standalone: true,
  imports: [SharelockCommonModule, RouterLink, MatIconModule],
  templateUrl: './footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  private readonly destroyRef = inject(DestroyRef);
  copyright = COPYRIGHT;
  footerContactData = FOOTER_CONTACT_DATA;

  navigationUnits$: Observable<INavigationDataTreeUnit[]>;

  constructor(private navigationService: NavigationService) {
    this.navigationUnits$ = this.navigationUnitsFiltered$;
  }

  private get navigationUnitsFiltered$(): Observable<
    INavigationDataTreeUnit[]
  > {
    return (this.navigationUnits$ = this.navigationService
      .getFilterednavigationUnits$({
        componentSelection: ENavigationUnitComponentSelection.FOOTER,
        hasLabel: true,
        flatten: true,
      })
      .pipe(takeUntilDestroyed(this.destroyRef)));
  }
}
