import {
  HttpInterceptorFn,
  HttpRequest,
  HttpHandlerFn,
  HttpEvent,
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { inject } from '@angular/core';
import { SnackBarService } from '../../share/services/utils/snack-bar.service';
import { TranslateService } from '@ngx-translate/core';
import { IHttpErrorResponseDetails } from '../model/api/http-response.model';

export const apiErrorInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  const snackBar = inject(SnackBarService);
  const translate = inject(TranslateService);

  return next(req).pipe(
    catchError((error: IHttpErrorResponseDetails) => {
      if (error.status === 429) {
        snackBar.openSnackbarError(
          {
            label: translate.instant('SHARED.ERROR.TOO_MANY_REQUESTS'),
            actionLabel: translate.instant('SHARED.ERROR.OK_UNDERSTAND'),
          },
          20000
        );
      }
      return throwError(() => error);
    })
  );
};
