import {
  Component,
  ChangeDetectionStrategy,
  AfterViewChecked,
  inject,
  DestroyRef,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  HostListener,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { SharelockCommonModule } from '../../../../share/modules/sharelock-common.module';
import { NavigationService } from '../../../services/navigation.service';
import { AccountButtonsComponent } from '../components/account-buttons/account-buttons.component';
import { LanguageButtonComponent } from '../components/language-button/language-button.component';
import { TopBarNavigationButtonsComponent } from './navigation-buttons/top-bar-navigation-buttons.component';
import { ENavigationRoute } from '../../../model/navigation.model';
import { RouterLink } from '@angular/router';
import { AccountDataService } from '../../../services/auth/account-data.service';
import { filter } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'shrl-top-bar',
  standalone: true,
  imports: [
    SharelockCommonModule,
    MatButtonModule,
    MatIconModule,
    TopBarNavigationButtonsComponent,
    LanguageButtonComponent,
    MatRippleModule,
    AccountButtonsComponent,
    RouterLink,
  ],
  templateUrl: './top-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopBarComponent implements AfterViewChecked {
  private readonly destroyRef = inject(DestroyRef);

  @ViewChild('emptySpaceCointainer') emptySpaceCointainer?: ElementRef;
  @ViewChild('rightContainer') rightContainer?: ElementRef;

  ENavigationRoute = ENavigationRoute;
  isSidenavActive?: boolean;

  private maxRightContainerSpace = 0;
  private changedLang = false;

  constructor(
    private navigationService: NavigationService,
    private accountDataService: AccountDataService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef
  ) {
    this.setNavigationServiceSubscribers();
    this.setAccountDataServiceSubscribers();
    this.setLangChangeSubscriber();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {}

  ngAfterViewChecked() {
    this.setContainerSpaceAfterViewChecked();
    this.setSidenavVisibility();
  }

  sidenavToggle() {
    this.navigationService.sidenavToggle();
  }

  private setContainerSpaceAfterViewChecked() {
    if (this.isSidenavActive === false) {
      this.changedLang
        ? this.resetMaxRightContainerSpace()
        : this.setMaxRightContainerSpace();
      this.changedLang = false;
    }
  }

  private setNavigationServiceSubscribers() {
    this.navigationService.isSidenavActive$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((matchFullPath) => (this.isSidenavActive = !!matchFullPath));
  }

  private setAccountDataServiceSubscribers() {
    this.accountDataService.isLoggedIn$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((isLogged) => !isLogged)
      )
      .subscribe(this.resetMaxRightContainerSpace);
  }

  private setLangChangeSubscriber() {
    this.translate.onDefaultLangChange
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => (this.changedLang = true));
  }

  private setSidenavVisibility() {
    if (this.totalSpace == null) {
      return;
    }
    if (this.totalSpace <= this.maxRightContainerSpace) {
      this.navigationService.setSideNavActive(true);
    } else {
      this.navigationService.setSideNavActive(false);
      this.navigationService.sidenavOpened(false);
    }
    this.cdr.detectChanges();
  }

  private setMaxRightContainerSpace() {
    if (this.rightContainerSpace == null) {
      return;
    }
    if (this.rightContainerSpace > this.maxRightContainerSpace) {
      this.maxRightContainerSpace = this.rightContainerSpace;
    }
  }

  private resetMaxRightContainerSpace = () => {
    this.maxRightContainerSpace = 0;
    this.setMaxRightContainerSpace();
  };

  // Total space without logo and external paddings
  private get totalSpace(): number | undefined {
    if (this.emptySpace == null || this.rightContainerSpace == null) {
      return undefined;
    }
    return this.emptySpace + this.rightContainerSpace;
  }

  private get emptySpace(): number | undefined {
    return this.emptySpaceCointainer?.nativeElement?.offsetWidth;
  }

  private get rightContainerSpace(): number | undefined {
    return this.rightContainer?.nativeElement?.offsetWidth;
  }
}
