import { INavigationDataTreeUnit } from '../../model/navigation.model';
import { EUserRole } from '../../model/user.model';

const isVisibleForRoles = (
  navUnit: INavigationDataTreeUnit,
  userRoles: EUserRole[]
) =>
  !!navUnit.visibleForUsers?.some((userType) => userRoles.includes(userType));

export const isNavUnitVisible = (
  navUnit: INavigationDataTreeUnit,
  userRoles?: EUserRole[]
): boolean =>
  userRoles
    ? isVisibleForRoles(navUnit, userRoles)
    : !navUnit.visibleForUsers?.length;
